<template>
  <div class="animated fadeIn">
    <h1>Помощь</h1>
    <p>Поддержка пользователей по<br>
      телефону: <a href="tel:849968111093999">(8-499-681-1109 доб. 3999)</a><br>
      email: <a href="mailto:etp_info@pecom.ru">etp_info@pecom.ru</a></p>
  </div>
</template>

<script>
export default {
  name: 'Help',
};
</script>
